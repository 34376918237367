import React from 'react'

import './footer.styles.scss'

let today = new Date();
let currentYear = today.getFullYear(); 

const LeFooter = () => {
    return (
        <div className='footer'>
            <h3>© Ma-Ve {currentYear}</h3>
        </div>
    )
}

export default LeFooter
