import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/svg/denk.svg'
import Button from '../../components/button/button.component'

import './header2.styles.scss'

class Header2 extends React.Component {

    componentDidMount() {
        const hamburger = document.querySelector('.hamburger')
        const navLinks = document.querySelector('.naviLinks')
        const links = document.querySelectorAll('.naviLinks li')

        hamburger.addEventListener('click', () => {
            navLinks.classList.toggle('open')
            hamburger.classList.toggle('toggle')
        })
        links.forEach(item => {
            item.addEventListener('click', () => {
                navLinks.classList.toggle('open')
                hamburger.classList.toggle('toggle')
            })
        })

    }

    render() {
        return (
            <div>
                <nav>
                    <div className="hamburger">
                        <div className="line line1"></div>
                        <div className="line line2"></div>
                        <div className="line line3"></div>
                    </div>
                    <div className="NavLogo">
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <ul className="naviLinks">
                        <Link to='/'>
                            <li>
                                <div>Home</div>
                            </li>
                        </Link>
                        <Link to='/over-ons'>
                            <li>
                                <div>Over Ma-Ve</div>
                            </li>
                        </Link>
                        <Link to='/werkwijze'>
                            <li>
                                <div>Onze Werkwijze</div>
                            </li>
                        </Link>
                        <Link to='/projecten'>
                            <li>
                                <div>Onze Projecten</div>
                            </li>
                        </Link>
                        <Link to='/contact'>
                            <li>
                                <Button href="./contact" className="fullWidth nomar">Neem contact op</Button>
                            </li>
                        </Link>

                    </ul>
                </nav>
            </div>
        );
    }
}

export default Header2