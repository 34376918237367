import React from 'react'
import Button from '../../components/button/button.component'
import svg1 from '../../assets/svg/mave-light.svg'
import denk from '../../assets/svg/denk.svg'
import './intro.styles.scss'

function Intro() {
    return (
        <div>
            <section className="landing">
                <div className="divide"></div>
                <div className="intro mw">
                    <div className="intro-text">
                        <div className="hero-svg">
                            {/* <img className="imgLeft" src={denk} alt='svgImage' /> */}
                            <img className="imgRight" src={svg1} alt='svgDenk' />
                        </div>
                        <h2>Welkom op de website van MA-VE.</h2>
                        <div className="intro-text-content">
                            Gaat u een uitbouw, overkapping, schuur of iets anders laten bouwen, dan bent u bij Ma-ve op het juiste adres voor de heiwerkzaamheden van deze bouwwerken.
                            <br></br><br></br>
                            Wij gaan graag samen met u in overleg om de juiste methode te bepalen op basis van toepassing, omgeving en overige wensen en voorwaarden.
                            <br></br><br></br>
                            <h3>Wij zijn sterk in klein heiwerk</h3>
                        </div>
                        <Button href="./contact" className="fullWidth">Neem contact met ons op</Button>
                    </div>
                </div>
                <div className="divide2"></div>
            </section>
        </div>
    )
}

export default Intro
