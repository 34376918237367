import React from 'react'
import faceBook from '../../assets/svg/facebook.svg'
import axios from 'axios';
import './contact.styles.scss'

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            naam: '',
            email: '',
            telefoon: '',
            onderwerp: '',
            bericht: '',
            mailSent: false,
            error: null,
        }

        this.sendState = '';
        this.onSubmit = '';
        this.API_PATH = '../../api/contact/send.php'
    }

    render() {
        return (
            <div>
                <div className="contactLanding mw">
                    <div className="contactWelcome">
                        <div className="welcomeText">
                            <h3>Neem contact met ons op</h3>
                            Vul hieronder uw contact gegevens in en wij nemen zo spoedig mogelijk contact op!
                            Of klik hiernaast voor onze facebook pagina.
                        </div>
                        <div className="socials">
                            <a href="https://www.facebook.com/pages/category/Construction-Company/Ma-ve-1761346607434927/">
                                <img src={faceBook} alt="facebook" />
                            </a>
                        </div>

                    </div>

                    <div className="formContainer">
                        <form id="contact-form">
                            <label htmlFor="naam">Naam</label>
                            <input type="text" id="naam" name="naam" required onChange={this.onChangeHandler.bind(this)} />

                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" required onChange={this.onChangeHandler.bind(this)} />

                            <label htmlFor="telefoon">Telefoonnummer</label>
                            <input type="text" id="telefoon" name="telefoon" onChange={this.onChangeHandler.bind(this)} />

                            <label htmlFor="onderwerp">Onderwerp</label>
                            <input type="text" id="onderwerp" name="onderwerp" required onChange={this.onChangeHandler.bind(this)} />

                            <label htmlFor="bericht">Bericht</label>
                            <textarea name="bericht" id="bericht" cols="10" rows="4" required onChange={this.onChangeHandler.bind(this)}></textarea>

                            <input 
                                type="submit"
                                value="Verzenden"
                                className="button"
                                data-sitekey="6LeTLcEkAAAAAGFglYSRMr2YyE2JOT19zMVjNZ-W" 
                                data-callback='onSubmitCap' 
                                data-action='submit'
                                onClick={e => this.handleFormSubmit(e)} />
                        </form>
                        <div>
                            {this.state.mailSent &&
                                <div className="succesMail">Bedankt voor uw bericht, wij nemen zo spoedig mogelijk contact met u op.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChangeHandler(e) {
        this.setState({ [e.target.id]: e.target.value })
        if (e.target.value !== '' && document.querySelector('.nocaptcha')) {
            document.querySelectorAll('.nocaptcha').forEach(item => {
                item.remove()
            })
        }
    }

    onChange() {
        if (document.querySelector('.nocaptcha')) document.querySelector('.nocaptcha').remove();
    }

    onSubmitCap(token) {
        document.getElementById("contact-form").submit();
      }

    handleFormSubmit = e => {
        e.preventDefault();


        if (document.querySelector('.nocaptcha')) {
            document.querySelector('.nocaptcha').remove()
        }
        let naam = document.getElementById('naam').value;
        let email = document.getElementById('email').value;
        let telefoon = document.getElementById('telefoon').value;
        let onderwerp = document.getElementById('onderwerp').value;
        let bericht = document.getElementById('bericht').value;

        if (naam === '') {
            document.getElementById(`naam`).style.border = "1px solid red"
            document.querySelector('#naam').insertAdjacentHTML('afterend', '<div class="nocaptcha">Vul a.u.b. uw naam in</div>')
        }

        if (email === '') {
            document.getElementById(`email`).style.border = "1px solid red"
            document.querySelector('#email').insertAdjacentHTML('afterend', '<div class="nocaptcha">Vul a.u.b. uw email in</div>')
        }

        if (telefoon === '') {
            document.getElementById(`telefoon`).style.border = "1px solid red"
            document.querySelector('#telefoon').insertAdjacentHTML('afterend', '<div class="nocaptcha">Vul a.u.b. uw telefoonnummer in</div>')
        }

        if (onderwerp === '') {
            document.getElementById(`onderwerp`).style.border = "1px solid red"
            document.querySelector('#onderwerp').insertAdjacentHTML('afterend', '<div class="nocaptcha">Vul a.u.b. een onderwerp in</div>')
        }

        if (bericht === '') {
            document.getElementById(`bericht`).style.border = "1px solid red"
            document.querySelector('#bericht').insertAdjacentHTML('afterend', '<div class="nocaptcha">Vul a.u.b. uw bericht in</div>')
            return;
        }

        let button = document.querySelector('.button'),
            spinner = '<span class="spinner"></span>';

        if (!button.classList.contains('loading')) {
            button.classList.toggle('loading')
            button.innerHTML = spinner;
        }
        else {
            button.classList.toggle('loading')
            button.innerHTML = "Verzenden";
        }


        axios({
            method: 'POST',
            url: this.API_PATH,
            headers: {
                'content-type': 'application/json',
            },
            data: this.state
        })
            .then(result => {
                this.setState({
                    mailSent: true,
                })
                document.getElementById('contact-form').style.display = "none";
                document.getElementById('contact-form').style.visibility = "hidden";
                button.classList.toggle('loading')
                button.innerHTML = "Verzonden!"
            })
            .catch(error => this.setState({ error: error.response }));
    };
}

export default Contact
