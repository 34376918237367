import React from 'react'
import SimpleReactLightbox from "simple-react-lightbox";

import Gallerij from '../../components/gallery/gallery.component'



const Projecten = () => (
    <div>
        <SimpleReactLightbox>
        <Gallerij />
        </SimpleReactLightbox>
    </div>
)

export default Projecten




