import React from 'react'

import WerkwijzePageIntro from '../../components/werkwijzeIntro/werkwijzeIntro.component'



const WerkwijzePage = () => (
    <div>
        <WerkwijzePageIntro />
    </div>
)

export default WerkwijzePage
