import React from 'react'

import AboutPageIntro from '../../components/svg-page-intro/svg-page-intro.component'



const AboutPage = () => (
    <div>
        <AboutPageIntro />
    </div>
)

export default AboutPage
