import React from 'react'
import { Link } from 'react-router-dom'

import svg2 from '../../assets/svg/building_blocks.svg'
import heistelling from '../../assets/images/heistelling.jpg'

import './werkwijzeIntro.styles.scss'


const WerkwijzePageIntro = () => (
    <div className="WerkIntro">
        <div className="WerkSvgContainer">
        </div>
        <div className="WerkintroText mw">
            <h3>Onze Werkwijze</h3>
                De palen die wij slaan zijn gekoppelde grout injectiepalen (zeer trillingsarm, praktisch trillingvrij, grondverdringend)
                <br></br><br></br>
                Een gekoppelde injectiepaal is een stalen paal, waarbij de punt onderaan de stalen buis voorzien is van een iets verzwaarde voet en een injectie-opening. De buis is omhuld met cementgrout en gevuld met grout.
                Bij het installeren wordt de grond geheel verdrongen. Het op diepte brengen van de stalen buis geschiedt door op de kop van de buis te heien onder gelijktijdig injecteren van cementgrout. Tijdens het installeren fungeert dit grout als smeermiddel, waardoor de te overwinnen weerstand tijdelijk zeer sterk wordt gereduceerd. Na verharding levert het cementgrout een bijdrage aan de sterkte en stijfheid van de paal, draagt een gedeelte van de kracht over naar de grond en beschermt de stalen buis tegen corrosie.
                <br></br><br></br>
                De palen heien doen wij met een mini heistelling. Deze kan namelijk door de voordeur van een woonhuis naar binnen. Maar bijvoorbeeld ook door de poortdeur. Daardoor kunnen wij overal terecht, ook op de lastige plekken.
                <br></br><br></br>
            <img className='heistelling' src={heistelling} alt="heistelling" />
            <br></br><br></br>
                De heistelling waarmee wij werken is geheel door ons zelf ontwikkeld en geproduceerd. Wij heien met een valblok. We heien met machines waarmee we met een valblok op de buis heien. Met deze machine worden trillingen tot een minimum beperkt. De lengte van de stalen heipalen die wij heien is in principe onbeperkt.
                <br></br><br></br>
                Wij zijn voortdurend bezig om onze machines verder te ontwikkelen en de techniek te verbeteren. Hebt u meer vragen of bent u benieuwd wat wij voor u kunnen betekenen? Neem dan gerust <Link to='/contact'>contact</Link> met ons op.
            </div>
    </div>
)

export default WerkwijzePageIntro