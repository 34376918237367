import React from 'react'
import Intro from '../../components/intro/intro.component'
import Features from '../../components/features/features.component'

import './home.styles.scss'

function HomePage() {
    return (
        <div>
            <Intro />
            <Features />
        </div>
    )
}

export default HomePage
