import React from 'react'
import './contact.styles.scss'

import Contact from '../../components/contact/contact.component'

function ContactPage() {
    return (
        <div>
            <Contact/>
        </div>
    )
}

export default ContactPage
