import React from "react";
import { SRLWrapper } from 'simple-react-lightbox';
import images from '../../assets/scripts/photos'
import './gallery.styles.scss'

const Gallerij = () => {
  return (
    <div className="mw">
      <h3 className="h3-projecten">Onze Projecten</h3>
      <SRLWrapper>
        {!images ? (
          <span>Loading...</span>
        ) : (
            <div className="row">
              {images.map(({ id, src, caption }) => (
                <div key={id}>
                  <img src={src} alt={caption} />
                </div>
              ))}
            </div>
          )}
      </SRLWrapper>
    </div>
  )
}



export default Gallerij