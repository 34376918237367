import React from 'react';
import { Link } from 'react-router-dom';

import './button.styles.scss'

const Button = ({ children, href, klas, ...otherProps }) => (
    <Link to={href}>
        <button className={`${klas} custom-button`}
            {...otherProps}>
            {children}
        </button>
    </Link>
)

export default Button;